<template>
  <div class="about" :class="status">
    <section class="about-main">
      <header class="about-header">
        <h1 class="about-heading">Us</h1>
        <div class="about-img" ref="aboutimg"></div>
      </header>
      <div class="about-content">
        <p>When you sign up for Fanfolk, we instantly create a website with subscriptions built-in. Ready for your content, and your fans. It's a web-based app built by two brothers with deep knowledge in the industry.</p>
        <p>Fanfolk was started by digital creators who: write, create art, and share what we learn. Want to see an example? <a class="href" href="https://blog.fanfolk.com">Check out our blog</a>, it was built with Fanfolk.</p>
        <p>We ran in to this problem: our true fans were asking to pay us for our work. Our thought: let’s add all of our work to a website, and charge a monthly subscription for unlimited access (a similar model to Netflix, MasterClass, and other subscription services).</p>
        <p>We tried to set this up ourselves: building the website, hosting it, and setting up payments. Despite us being professional software designers and engineers, this was difficult. It shouldn’t be.</p>
        <p>We also saw challenges with existing solutions. We didn’t want our content getting lost in a timeline. We didn’t want to flood our fans with emails. We wanted a website with subscriptions.</p>
        <p>That’s why we created Fanfolk.</p>
        <p>Along the way, we found other creators running in to the same roadblocks. People with real-world skills who happened to have a following on Twitter, Instagram, or email. They were looking for a solution, and couldn’t find what they were looking for.</p>
        <p>We are product-first (which is why you haven't heard of us). We focus on creating value for our creators, then do as much as we can to support them. We align ourselves with the goals of our customers: we think it’s better that way.</p>
        <p>If you have any questions, or would like to learn more, please reach out. Fanfolk currently has a waitlist, add yourself to the list here.</p>
        <button class="ui-button" type="button" name="button" @click="navigate(`https://tom702312.typeform.com/to/GHZ4p8t2`)">
          Get Early Access
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import HoverEffect from 'hover-effect';

export default {
  name: 'Home',
  props: [
    'navigate'
  ],
  data() {
    return {
      animated: false,
      status: 'is-top'
    }
  },
  methods: {
  },
  created() {
    analytics.track('Page Viewed', { name: 'About' });
  },
  mounted() {
    // const ani = new HoverEffect({
    //   parent: this.$refs.aboutimg,
    //   intensity: 0.05,
    //   speedIn: 3,
    //   speedOut: 2,
    //   easing: 'Quad.easeOut',
    //   image1: require('@/assets/team1.jpg'),
    //   image2: require('@/assets/team2.jpg'),
    //   displacementImage: require('@/assets/teambg.jpg'),
    //   imagesRatio: .66666666666666666
    // });
    const ani = new HoverEffect({
      parent: this.$refs.aboutimg,
      intensity: 1,
      speedIn: 1,
      speedOut: 2,
      easing: 'Quad.easeOut',
      image1: require('@/assets/bro1.jpg'),
      image2: require('@/assets/bro2.jpg'),
      displacementImage: require('@/assets/teambg.jpg'),
      imagesRatio: .66666666666666666
    });
  },
};
</script>
<style scoped lang="scss">
  @import "../assets/styles/app.scss";
  .about {
    @include container;
    padding-top: 0;
    padding-bottom: 8rem;

    &.is-bottom {
      @include status;
    }
  }
  .about-header {
    padding-top: 0;
    transition: 1s ease-in all;

    &:hover {
      @include status;
    }
  }
  .about-heading {
    @include big-heading;
    position: relative;
    z-index: 1;
  }
  .about-content {
    @include copy;
    position: relative;
    z-index: 1;
  }
  .about-img {
    @include main-img;
    z-index: 0;
    top: -8%;
  }
  .href  {
    color: $color-ui;

    &:visited {
      color: #29B9E8;
    }
  }
  @media screen and (max-width: 800px) {
    .about-heading {
      margin-top: 3rem;
      margin-bottom: 0;
    }
    .about-img {
      position: absolute;
      height: 10rem;
      width: 100%;
      left: 0;
      top: 0;
    }
    .about-content {
      padding: 1em;
    }
  }
</style>
